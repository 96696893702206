<template>
  <section class="bg-custom-primary min-h-screen flex flex-col items-center">
    <div class="p-6 md:p-24 font-azonix w-full">
      <h1 class="text-center text-white text-4xl">Archer Roadmap</h1>
      
      <div class="flex justify-center mt-6">
        <div class="flex flex-col md:flex-row justify-center md:gap-6 w-full mt-8 text-white">

          <div class="flex flex-col items-center mb-8 md:mb-0">
            <h1 class="text-2xl text-center">{{ dates['blockOne'] }}</h1>
            <div class="text-center mt-4 rounded-xl h-96 w-72 p-4" :class="`bg-gradient-to-b from-[#b9364e] to-[#000000]`">
              <h2 class="text-xl">{{ titles['blockOne'] }}</h2>
              <div class="scrollbar mt-4 max-h-80 overflow-auto">
                <div v-for="data in map['one']" class=" bg-custom-secondary mb-2 rounded-xl p-2 font-montserrat">{{data}}</div>
              </div>

            </div>
          </div>

          <div class="flex flex-col items-center mb-8 md:mb-0">
            <h1 class="text-2xl text-center">{{ dates['blockTwo'] }}</h1>
            <div class="text-center mt-4 rounded-xl h-96 w-72 p-4" :class="`bg-gradient-to-b from-[#b9364e] to-[#000000]`">
              <h2 class="text-xl">{{ titles['blockTwo'] }}</h2>
              <div class="scrollbar mt-4 max-h-80 overflow-auto">
                <div v-for="data in map['two']" class=" bg-custom-secondary mb-2 rounded-xl p-2 font-montserrat">{{data}}</div>
              </div>
            </div>
          </div>

          <div class="flex flex-col items-center">
            <h1 class="text-2xl text-center">{{ dates['blockThree'] }}</h1>
            <div class="text-center mt-4 rounded-xl h-96 w-72 p-4" :class="`bg-gradient-to-b from-[#b9364e] to-[#000000]`">
              <h2 class="text-xl">{{ titles['blockThree'] }}</h2>
              <div class="scrollbar mt-4 max-h-80 overflow-auto">
                <div v-for="data in map['three']" class=" bg-custom-secondary mb-2 rounded-xl p-2 font-montserrat">{{data}}</div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </section>
</template>

<script>
      import json from './json/map.json'
      export default{
          data(){
              return{
                  map: json,
                  titles: json['source']['titles'],
                  dates: json['source']['dates'],
                  gradients: json['source']['gradients']

              }
          },


      }
</script>
